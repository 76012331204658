import React from 'react';
import LoginForm from "./Components/Login/login";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Customer from './Components/Login/customers';
import axios from 'axios';
import { API_URL } from './General';
import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';




// const App = () => {
  
//   const isAuthenticated = true;
const secret_key = "Mawingu2023"

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null); // Declare userData state
  const [loading, setLoading] = useState(true); 


  useEffect(() => {
    const storedData = localStorage.getItem('user');

    if (storedData) {
      var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserData(decryptedData); // Set the userData state here
    }

    const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

    const _token = `Bearer ${decryptedToken}`;

    if (_token) {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/sign_in`,
        headers: {
          'Authorization': _token
        }
      };

      axios.request(config)
        .then((response) => {
          if (response.data.statusCode === 401) {
            setIsAuthenticated(false);
          } else {
            setIsAuthenticated(true);
          }
        })
        .catch((error) => {
          setIsAuthenticated(false);
        })
        .finally(()=>{
          setLoading(false);
        });
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route
          path="/customers"
          element={isAuthenticated ? <Customer /> : <Navigate to="/login" />}
        />
        {/* Add more routes for other pages/components as needed */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;




