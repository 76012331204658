import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL, secret_key } from '../../General';
import './style.css';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';

const columns = [
  { header: 'id', key: 'id' },
  { header: 'CustomerName', key: 'Customer Name' },
  { header: 'CustomerNumber', key: 'Customer Number' },
  { header: 'Phone', key: 'Phone' },
  { header: 'Region', key: 'Region' },
];

function Customer() {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [filterRegion, setFilterRegion] = useState('');
  const navigate = useNavigate();

  let encryptedToken = localStorage.getItem('token');
  let decryptedToken = '';

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log('Error.');
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const fetchApplications = () => {
    let config = {
      method: 'get',
      url: `${API_URL}/get_customers`,
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        page: 1,
        limit: 200
      },
    };

    axios
      .request(config)
      .then((response) => {
        setApplications(response.data);
        // setFilteredApplications(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          navigate('login');
        }
      });
  };

  useEffect(() => {
    fetchApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegionFilter = (event) => {
    const selectedRegion = event.target.value;
    setFilterRegion(selectedRegion);

    if (selectedRegion === '') {
      setFilteredApplications(applications);
    } else {
      const filteredData = applications.filter(
        (customer) => customer.Region === selectedRegion
      );
      setFilteredApplications(filteredData);
    }
  };

  const workSheetName = 'CRM Customers';
  const workBookName = 'CRM Customers';
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    try {
      const fileName = workBookName;

      const worksheet = workbook.addWorksheet(workSheetName);
      worksheet.columns = columns;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      filteredApplications.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      worksheet.eachRow({ includeEmpty: false }, (row) => {
        const currentCell = row._cells;

        currentCell.forEach((singleCell) => {
          const cellAddress = singleCell._address;
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('<<<ERROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
  };

  return (
    <div style={{ paddingLeft: '10px' }}>
      <form className="form-inline justify-content-end searchBar">
        <div className="input-group input-group-sm excelexport">
          <label htmlFor="regionFilter">Filter by Region:</label>
          <select
            id="regionFilter"
            className="form-select"
            value={filterRegion}
            onChange={handleRegionFilter}
          >
            <option value="">All Regions</option>
            {[...new Set(applications.map((customer) => customer.Region))].map(
              (region, index) => (
                <option key={index} value={region}>
                  {region}
                </option>
              )
            )}
          </select>
          <button onClick={saveExcel}>Export</button>
        </div>
      </form>

      <table id="example" className="table table-striped">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Customer Number</th>
            <th>Phone</th>
            <th className="hideonsmall">Region</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((customer, id) => (
            <tr key={id}>
              <td className="shorten-textbig">{customer?.customer_name}</td>
              <td>{customer.id}</td>
              <td className="hideonsmall shorten-textbig">{customer.customer_phone}</td>
              <td className="shorten-textbig">{customer.splynx_location}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Customer;
