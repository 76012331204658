import axios from 'axios';
import CryptoJS from 'crypto-js';

export const API_URL = 'https://sms-backend.mawingu.co';

export const secret_key = "mawingu@2024secret"
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
}

export function shortenDate(inputDate) {
  const date = new Date(inputDate);
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

    const _token = `Bearer ${decryptedToken}`;

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    marginTop: '0px',
    maxHeight: '650px',
    overflow: 'auto'
  },
};

