
import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../General';
import './style.css';
import { useNavigate } from 'react-router-dom';

const FormHeader = (props) => (
  <h2 id="headerTitle">{props.title}</h2>
);

const FormInput = (props) => (
  <div className="row">
    <label>{props.description}</label>
    <input
      type={props.type}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  </div>
);

const FormButton = (props) => (
  <div id="button" className="row">
    <button>{props.title}</button>
  </div>
);

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let config = {
      method: 'post',
      url: `${API_URL}/sign_in`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: username,
        password: password
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        navigate('/customers')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id="loginform">
      <FormHeader title="Mawingu SMS Portal" />
      <form onSubmit={handleSubmit}>
        <FormInput
          description="Username"
          placeholder="Enter your email"
          type="text"
          value={username}
          onChange={handleUsernameChange}
        />
        <FormInput
          description="Password"
          placeholder="Enter your password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <FormButton title="Log in" />
      </form>
    </div>
  );
};

export default LoginForm;
